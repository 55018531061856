import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../redux/actions/authAction';
import Footer from "../components/footer/Footer";
import Logo from "../images/foodbyte poster (transparent).png";
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(email));
    };

    return (
        <div className="auth_page">
            <img className="logo" src={Logo} alt="Foodbyte" />
            <div className="footer">
                <Footer />
            </div>

            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{ color: "#30115d" }}>
                    <b>Forgot Password</b>
                </h3>

                {/* Display Alerts */}
                {alert.loading && <div className="alert alert-info">Loading...</div>}
                {alert.success && <div className="alert alert-success">{alert.success}</div>}
                {alert.error && <div className="alert alert-danger">{alert.error}</div>}

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1"><b>Email</b></label>
                    <input 
                        type="email" 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        name="email"
                        aria-describedby="emailHelp" 
                        onChange={(e) => setEmail(e.target.value)} 
                        value={email} 
                        style={{ border: "2px solid #27004b" }} 
                    />
                </div>

                <button 
                    type="submit" 
                    className="btn btn-dark w-100" 
                    disabled={!email || alert.loading}
                >
                    Submit
                </button>

                <p className="my-2">
                    <br />
                    <b> Got a great memory? </b> 
                    <Link to="/" style={{ color: "rgb(63, 0, 117)", textDecoration: "none" }}>
                        <b> Get back to login </b>
                    </Link>
                </p>
            </form>
        </div>
    );
};

export default ForgotPassword;