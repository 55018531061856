import React, { useEffect } from 'react'
import Ads from '../ads/Ads'
import Status from '../components/home/Status'
import Posts from '../components/home/Posts'
import RightSideBar from '../components/home/RightSideBar'
import { useSelector } from 'react-redux'
import Loading from '../components/alert/Loading'

let scroll = 0;

const Home = () => {
    const { homePosts } = useSelector(state => state)

    window.addEventListener('scroll', () => {
        if (window.location.pathname === '/') {
            scroll = window.pageYOffset
            return scroll;
        }
    })

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: scroll, behavior: 'smooth' })
        }, 100)
    }, [])

    return (
        <div className="home row mx-0">
            <Ads />
            <div className="col" style={{ marginLeft: "20%" }}>
                <Status />
                {
                    homePosts.loading
                        ? <Loading alt="loading" className="d-block mx-auto" style={{ height: "300px", width: "300px" }} />
                        : (homePosts.result === 0 && homePosts.posts.length === 0)
                            ? <h2 className="text-center"></h2>
                            : <div className='final_post'>
                                <Posts /> <br />
                            </div>
                }
            </div>
            <div className="col-md-3">
                <RightSideBar />
            </div>
        </div>
    )
}

export default Home