import { useEffect } from 'react';

const LoadMoreBtn = ({ load, handleLoadMore }) => {
    useEffect(() => {
        const handleScroll = () => {
            // Check if we are near the top of the page
            if (document.documentElement.scrollTop <= 50) {
                if (!load) {
                    handleLoadMore(); // Trigger loading more posts
                }
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [load, handleLoadMore]);

    return null; // No visible button
};

export default LoadMoreBtn;