import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authAction';
import Avatar from '../Avatar';
import NotifyModal from '../NotifyModal';

const Menu = () => {
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();

    // Check if the screen width is mobile size
    useEffect(() => {
        const checkMobile = () => {
            const isMobileView = window.innerWidth <= 766; // Adjust the width as needed for mobile
            setIsMobile(isMobileView);
        };

        checkMobile(); // Initial check
        window.addEventListener('resize', checkMobile); // Add resize event listener

        return () => window.removeEventListener('resize', checkMobile); // Clean up event listener
    }, []);

    const navLinks = [
        { label: 'Home', icon: 'home', path: '/' },
        { label: 'Message', icon: 'forum', path: '/message' },
        { label: 'Find People', icon: 'people', path: '/findPeople' }, // Only for mobile
    ];

    const { auth, notify } = useSelector(state => state);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isActive = (pn) => {
        return pn === pathname ? 'active' : '';
    };

    useEffect(() => {
        // Automatically redirect "Find People" to home on desktop view
        if (!isMobile && pathname === '/findPeople') {
            history.replace('/');
        }
    }, [isMobile, pathname, history]);

    return (
        <div className="menu">
            <ul className="navbar-nav flex-row">
                {navLinks.map((link, index) => {
                    // Only render "Find People" for mobile view
                    if (link.label === 'Find People' && !isMobile) {
                        return null;
                    }

                    return (
                        <li className={`nav-item px-2 ${isActive(link.path)}`} key={index}>
                            <Link
                                className="nav-link"
                                to={link.path}
                            >
                                <span className="material-icons">{link.icon}</span>
                            </Link>
                        </li>
                    );
                })}

                {/* Notification dropdown */}
                <li className={`nav-item ${isMobile ? 'dropup' : 'dropdown'}`} style={{ opacity: 1 }}>
                    <span className="nav-link position-relative" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="material-icons" style={{ color: notify.data.length > 0 ? 'crimson' : '' }}>
                            notifications
                        </span>
                        <span className="notify_length">{notify.data.length}</span>
                    </span>

                    <div 
                        className="dropdown-menu" 
                        aria-labelledby="navbarDropdown"
                        style={{ transform: `translateX(${isMobile ? '80px' : '0px'})` }}>
                        <NotifyModal />
                    </div>
                </li>

                {/* Dashboard dropdown */}
                {/* <li className={`nav-item ${isMobile ? 'dropup' : 'dropdown'}`} style={{ opacity: 1 }}>
                    <span className="nav-link" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="material-icons">apps</span>
                    </span>

                    <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ transform: `translateX(${isMobile ? '62px' : '0px'})`, paddingBottom: "35%" }}>
                        <Link className="dropdown-item" to={`/myRestaurant`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">storefront</span>
                            <b>My Restaurant</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/order`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">restaurant</span>
                            <b>Order Food</b>
                        </Link>
                        
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/groceries`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">shopping_bag</span>
                            <b>Buy Groceries</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/cart`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">shopping_cart</span>
                            <b>Cart</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/consultancy`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">handshake</span>
                            <b>Consultancy</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/donation`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">volunteer_activism</span>
                            <b>Donation</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/report`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">campaign</span>
                            <b>Raise Voice</b>
                        </Link>
                    </div>
                </li> */}

                {/* Avatar dropdown */}
                <li className={`nav-item ${isMobile ? 'dropup' : 'dropdown'}`} style={{ opacity: 1 }}>
                    <span className="nav-link" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <Avatar src={auth.user.avatar} size="small-avatar" className="avatar"/>
                    </span>

                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to={`/profile/${auth.user._id}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">person</span>
                            <b>Profile</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/contact`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">support_agent</span>
                            <b>Contact Us</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/getTheApp`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">download</span>
                            <b>Get The App</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/settings`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons">settings</span>
                            <b>Settings</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/"
                            onClick={() => dispatch(logout())} style={{ display: 'flex', alignItems: 'center', marginBottom: "5%" }}>
                            <span className="material-icons">logout</span>
                            <b>Logout</b>
                        </Link>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Menu;
