import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { register } from '../redux/actions/authAction';
import Footer from '../components/footer/Footer';
import TermsCondition from '../components/settings/Terms and Condition/TermsCondition';
import Logo from "../images/foodbyte poster (transparent).png";
import {Scrollbars} from "react-custom-scrollbars-2";

const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "North Korea",
    "South Korea",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

const Register = () => {
    const { auth, alert } = useSelector(state => state);
    const dispatch = useDispatch();
    const history = useHistory();

    const initialState = { 
        fullname: '', username: '', email: '', country: '', password: '', cf_password: '', gender: 'Male', terms_and_condition: false
    };

    const [userData, setUserData] = useState(initialState);
    const { fullname, username, email, country, password, cf_password, terms_and_condition } = userData;

    const [typePass, setTypePass] = useState(false);
    const [typeCfPass, setTypeCfPass] = useState(false);

    useEffect(() => {
        if (auth.token) history.push("/");
    }, [auth.token, history]);

    const handleChangeInput = e => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleTermsChange = e => {
        setUserData({ ...userData, terms_and_condition: e.target.checked });
    };

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(register(userData));
    };

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte" />
            <div className='footer'>
                <Footer />
            </div>

            <form onSubmit={handleSubmit} className="registration">
                <h3 className="text-uppercase text-center mb-4" style={{ color: "#27004b" }}><b>Registration</b></h3>
                
                {/* Display Alerts */}
                {alert.loading && <div className="alert alert-info">Loading...</div>}
                {alert.success && <div className="alert alert-success">{alert.success}</div>}
                {alert.error && <div className="alert alert-danger">{alert.error}</div>}

                <Scrollbars className="scrollbars" style={{ width: "100%", height: "30vh"}} autoHide autoHideTimeout={1000} autoHideDuration={200}>

                <h6 className="instruction">  Please fill out the form by scrolling down. </h6>

                {/* Full Name */}
                <div className="form-group">
                    <label htmlFor="fullname"><b>Full Name</b></label>
                    <input type="text" className="form-control" id="fullname" name="fullname"
                        onChange={handleChangeInput} value={fullname}
                        style={{ border: "2px solid #27004b", background: `${alert.fullname ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.fullname ? alert.fullname : ''}
                    </small>
                </div>

                {/* Username */}
                <div className="form-group">
                    <label htmlFor="username"><b>Username</b></label>
                    <input type="text" className="form-control" id="username" name="username"
                        onChange={handleChangeInput} value={username.toLowerCase().replace(/ /g, '')}
                        style={{ border: "2px solid #27004b", background: `${alert.username ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.username ? alert.username : ''}
                    </small>
                </div>

                {/* Email */}
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1"><b>Email</b></label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email"
                        onChange={handleChangeInput} value={email}
                        style={{ border: "2px solid #27004b", background: `${alert.email ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.email ? alert.email : ''}
                    </small>
                </div>

                {/* Country */}
                <div className="form-group">
                    <label htmlFor="country"><b>Country</b></label>
                    <select id="country" name="country" className="form-control" value={country} 
                        onChange={handleChangeInput} style={{ border: "2px solid #27004b" }}>
                        <option value="" className="option">Select your country</option>
                        {countryList.map((e, index) => (
                            <option className='option' key={index} value={e}>{e}</option>
                        ))}
                    </select>
                    <small className="form-text text-danger">
                        {alert.country ? alert.country : ''}
                    </small>
                </div>

                {/* Password */}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1"><b>Password</b></label>
                    <div className="pass">
                        <input type={typePass ? "text" : "password"}
                            className="form-control" id="exampleInputPassword1"
                            onChange={handleChangeInput} value={password} name="password"
                            style={{ border: "2px solid #27004b", background: `${alert.password ? '#fd2d6a14' : ''}` }} />
                        <small onClick={() => setTypePass(!typePass)} style={{ marginRight: "2%" }}>
                            <b>{typePass ? 'Hide' : 'Show'}</b>
                        </small>
                    </div>
                    <small className="form-text text-danger">
                        {alert.password ? alert.password : ''}
                    </small>
                </div>

                {/* Confirm Password */}
                <div className="form-group">
                    <label htmlFor="cf_password"><b>Confirm Password</b></label>
                    <div className="pass">
                        <input type={typeCfPass ? "text" : "password"}
                            className="form-control" id="cf_password"
                            onChange={handleChangeInput} value={cf_password} name="cf_password"
                            style={{ border: "2px solid #27004b", background: `${alert.cf_password ? '#fd2d6a14' : ''}` }} />
                        <small onClick={() => setTypeCfPass(!typeCfPass)} style={{ marginRight: "2%" }}>
                            <b>{typeCfPass ? 'Hide' : 'Show'}</b>
                        </small>
                    </div>
                    <small className="form-text text-danger">
                        {alert.cf_password ? alert.cf_password : ''}
                    </small>
                </div>

                {/* Gender */}
                <div className="row justify-content-between mx-0 mb-1">
                    <label htmlFor="Male">
                        <b>Male:</b> <input type="radio" id="Male" name="gender"
                            value="Male" defaultChecked onChange={handleChangeInput} style={{ cursor: "pointer" }} />
                    </label>

                    <label htmlFor="Female">
                        <b>Female:</b> <input type="radio" id="=Female" name="gender"
                            value="Female" onChange={handleChangeInput} style={{ cursor: "pointer" }} />
                    </label>

                    <label htmlFor="Other">
                        <b>Other:</b> <input type="radio" id="Other" name="gender"
                            value="Other" onChange={handleChangeInput} style={{ cursor: "pointer" }} />
                    </label>
                </div>

                </Scrollbars>

                <div className="form-group">
                    <div className="row justify-content-between mx-0 mb-1" style={{marginTop: "5%"}}>
                        <label style={{display: "flex"}}>
                            <input type="radio" id="terms_and_condition" name="terms_and_condition" checked={terms_and_condition} value={terms_and_condition} onChange={handleTermsChange} style={{ cursor: "pointer", marginRight: "2%", marginTop: "0.8%" }} /> <TermsCondition />
                        </label>
                    </div>
                    <small className="form-text text-danger">
                        {alert.terms_and_condition ? alert.terms_and_condition : ''}
                    </small>
                </div>

                <button type="submit" className="btn btn-dark w-100">
                    Register
                </button>

                <p className="my-2">
                    <br />
                    <b>Already have an account?</b> <Link to="/" style={{ color: "rgb(63, 0, 117)", textDecoration: "none" }}>
                        <b>Login now</b>
                    </Link>
                </p>
            </form>
        </div>
    );
};

export default Register;