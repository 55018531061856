import React, { useState, useEffect } from 'react'
import PostThumb from '../PostThumb'
import { getDataAPI } from '../../utils/fetchData'
import LoadingMini from '../alert/LoadingMini'
import LoadMoreBtn from '../LoadMoreBtn'

const Posts = ({ auth, id, profile }) => {
    const [posts, setPosts] = useState([])
    const [result, setResult] = useState(0)
    const [page, setPage] = useState(1)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        const fetchInitialPosts = () => {
            profile.posts.forEach(data => {
                if (data._id === id) {
                    setPosts(data.posts)
                    setResult(data.result)
                    setPage(data.page)
                }
            })
        }

        fetchInitialPosts()
    }, [profile.posts, id])

    const handleLoadMore = async () => {
        if (load) return
        setLoad(true)

        const res = await getDataAPI(`user_posts/${id}?page=${page}`, auth.token)

        const uniquePosts = res.data.posts.filter(
            newPost => !posts.some(existingPost => existingPost._id === newPost._id)
        )

        setPosts(prevPosts => [...prevPosts, ...uniquePosts])
        setResult(res.data.result)
        setPage(prevPage => prevPage + 1)
        setLoad(false)
    }

    return (
        <div>
            <PostThumb posts={posts} />

            {load && <LoadingMini alt="loading" className="d-block mx-auto" style={{ height: "300px", width: "300px" }} />}

            <LoadMoreBtn result={result} page={page} load={load} handleLoadMore={handleLoadMore} />
        </div>
    )
}

export default Posts